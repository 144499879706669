import {useAuth} from '@/hooks/useAuth'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useCountries} from '@/hooks/useCountries'
import {UserRoleEnum, UserRoleEnumSchema} from '@/types/UserRole'
// import {getUserRoleUseCase} from '@/useCases/getUserRoleUseCase'
// import {useQuery} from '@tanstack/react-query'
import {PropsWithChildren, createContext, useMemo} from 'react'

type UserRoleContextType = {
  isReady: boolean
  isAuthorized: boolean
  userRole?: UserRoleEnum
  userAccessRules: string[]
  error: Error | null
}

const initialState: UserRoleContextType = {
  isReady: false,
  isAuthorized: false,
  userRole: undefined,
  userAccessRules: [],
  error: null,
}

export const UserRoleContext = createContext<UserRoleContextType>(initialState)

export const UserRoleProvider: React.FC<PropsWithChildren> = ({children}) => {
  // const {isAuthenticated, claims} = useAuth()
  const {claims} = useAuth()

  const {countries} = useCountries()

  // const {data, error} = useQuery({
  //   queryKey: [`userRole-${claims?.gpid}`],
  //   queryFn: ({signal}) => getUserRoleUseCase(signal),
  //   enabled: isFeatureEnabled(FeatureToggle.userRole) && isAuthenticated,
  // })

  const value = useMemo(() => {
    if (!isFeatureEnabled(FeatureToggle.userRole)) {
      return {
        ...initialState,
        isReady: true,
        isAuthorized: true,
        userRole: UserRoleEnumSchema.Values.admin,
      }
    }

    // if (!data || error) {
    //   return {
    //     ...initialState,
    //     error,
    //   }
    // }

    const userRole = claims.roles?.[0] as UserRoleEnum
    // const userAccessRules = accessRuleToCountryCodeAdapter(data[0]?.accessRules?.[0]?.values ?? [])
    const userAccessRules = accessRuleToCountryCodeAdapter([''])
    const isAdmin = userRole === UserRoleEnumSchema.Values.admin || true
    const isAuthorized = isAdmin ? true : countries.some(item => userAccessRules.includes(item.code))

    return {
      ...initialState,
      isReady: true,
      isAuthorized,
      userRole,
      userAccessRules,
    }
  }, [countries, claims.roles])

  return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>
}

/**
 * Parse market from accessRules pattern adapting it to ["countryCode"] format.
 * Accepted patterns:
 *  "region-market" -> ["Europe-TR"]
 *  "market" ->  ["TR"]
 * @param accessRule array of accessRules strings
 * @returns array of countrCodes user has access to: ["TR"]
 */
const accessRuleToCountryCodeAdapter = (accessRule: string[] = []) => {
  return accessRule.map(rule => {
    const [, market] = rule.split('-')
    return market ?? rule
  })
}
