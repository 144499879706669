import {UserRoleContext} from '@/helpers/UserRole/UserRoleProvider'
import {getCountries} from '@/helpers/envConfig'
import {store, useAppSelector} from '@/store'
import {setCountry} from '@/store/slices/commonSlice'
import {useContext, useEffect, useMemo} from 'react'

export const useCountries = (_userAccessRules?: string[]) => {
  const {userAccessRules} = useContext(UserRoleContext)
  const {countries, country} = useAppSelector(state => state.common)

  const localCountries = useMemo(() => {
    const countriesFromUser = _userAccessRules || userAccessRules
    const allCountries = getCountries()
    const filteredCountries = allCountries.filter(country => countriesFromUser.includes(country))
    return countriesFromUser.length ? filteredCountries : allCountries
  }, [_userAccessRules, userAccessRules])

  useEffect(() => {
    if (country.code) {
      return
    }

    const newCountry = countries.find(c => c.code === localCountries[0])
    if (newCountry) {
      store.dispatch(setCountry(newCountry))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, country])

  return {countries, country}
}
