import {useCallback, useEffect, useState} from 'react'
import {TokenClaims} from '@azure/msal-common'
import {useMsal} from '@azure/msal-react'
import {loginRequest} from '@/helpers/Auth/authConfig'

export type ClaimsProps = TokenClaims & {
  firstName?: string
  lastName?: string
}

export const useAuth = () => {
  const {instance, accounts, inProgress} = useMsal()
  const [accessToken, setAccessToken] = useState<string | undefined>()

  const fetchToken = useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      setAccessToken(response.accessToken)
      localStorage.setItem('msalAccessToken', response.idToken) //here idToken is used to authenticate APIs
    } catch (error) {
      console.error('Error fetching token:', error)
    }
  }, [accounts, instance])

  const signInWithRedirect = () => {
    instance.loginRedirect(loginRequest)
  }

  const signOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }

  const handleLogin = (loginType: string) => {
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest).catch(e => {
        console.log(e)
      })
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e)
      })
    }
  }

  useEffect(() => {
    if (accounts.length > 0) fetchToken()
  }, [accounts, fetchToken])

  return {
    authState: inProgress,
    instance,
    accessToken,
    handleLogin,
    signInWithRedirect,
    signOut,
    account: accounts[0],
    isAuthenticated: accounts.length > 0,
    claims: (accounts[0]?.idTokenClaims || {}) as ClaimsProps,
  }
}
