import {cn} from '@/helpers/shadcn/utils'
import {ComponentProps, ReactNode} from 'react'
import {useIntl} from 'react-intl'
import {PageHeader} from '../../../shared-components/PageHeader'
import {useAppSelector} from '@/store'
import {selectDarkMode} from '@/store/slices/commonSlice'

type PageContentProps = ComponentProps<'div'> & {
  title?: string
  pageHeaderContent?: ReactNode
  pageContentWrapperClassName?: string
}

export const PageContent = ({
  title,
  pageHeaderContent,
  pageContentWrapperClassName,
  className,
  children,
  ...props
}: PageContentProps) => {
  const {formatMessage} = useIntl()
  const isDark = useAppSelector(selectDarkMode)

  return (
    <div className={cn('flex h-full flex-col text-base', pageContentWrapperClassName)} {...props}>
      {(title || pageHeaderContent) && (
        <PageHeader title={title ? formatMessage({id: title}) : ''}>{pageHeaderContent}</PageHeader>
      )}
      <div
        className={cn(
          `shadow mb-6 mt-2 flex h-full w-full flex-col overflow-y-auto rounded-lg border-none ${
            isDark ? 'bg-layer-dark' : 'bg-layer-01'
          } p-6`,
          className
        )}
        data-cy="page-content"
      >
        {children}
      </div>
    </div>
  )
}
