import {COUNTRIES_QUERY_KEY, LANGUAGES_QUERY_KEY} from '@/config/constants'
import {useAppDispatch, useAppSelector} from '@/store'
import {setCountries, setLanguages} from '@/store/slices/commonSlice'
import {CountriesType} from '@/types/CountriesResponse'
import {LanguagesType} from '@/types/LanguageResponse'
import {getCountriesUseCase, getLanguagesUseCase} from '@UseCases'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'

export const useMain = () => {
  const dispatch = useAppDispatch()
  const {country} = useAppSelector(state => state.common)

  // Fetch countries
  const {data: countriesData, isSuccess: isCountriesSuccess} = useQuery({
    queryKey: [COUNTRIES_QUERY_KEY],
    queryFn: ({signal}: {signal: AbortSignal}) => getCountriesUseCase(signal),
    retry: 1,
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  // Fetch languages, enabled only when countryId is available
  const {data: languagesData, isSuccess: isLanguagesSuccess} = useQuery({
    queryKey: [LANGUAGES_QUERY_KEY, country.id],
    queryFn: ({signal}: {signal: AbortSignal}) => getLanguagesUseCase(country.id, signal),
    retry: 1,
    enabled: !!country.id,
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  // Dispatch actions to update the countries and languages state
  useEffect(() => {
    if (isCountriesSuccess && countriesData) {
      dispatch(setCountries(countriesData.content as CountriesType))
    }
  }, [isCountriesSuccess, countriesData, dispatch])

  useEffect(() => {
    if (isLanguagesSuccess && languagesData) {
      dispatch(setLanguages(languagesData.content as LanguagesType))
    }
  }, [isLanguagesSuccess, languagesData, dispatch])
}
